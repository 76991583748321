// @import url("https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$purple: #9261c6;
$pink: #ff7aa3;
$white: #ffffff;
$muted: #a0a9b1;

$primary: #00e3b4;
$secondary: #00e3b4;
$success: #00e3b4;
$info: #24bae7;
$warning: #f0a901;
$danger: #ec5252;
$light: #b3b3b3;
$dark: #6e6e6e;

$discord: #868fff !default;

// stylelint-disable
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #efefef;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "purple": $purple,
  "pink": $pink,
  "white": $white,
  "light": $light,
);

$body-color: #d3dbe2;

$footer-bg: #0f1b42;

$font-size-base: 0.9rem;

$box-shadow: 0px 8px 35px 0px rgba($gray-700, 0.05);

$font-family-primary: "Montserrat", sans-serif;

// Secondary font
$font-family-secondary: "Montserrat", sans-serif;

$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

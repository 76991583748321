/*
Template Name: Invoza - Bootstrap 4 Landing Page Tamplat
Author: Themesdesign
File: Main scss file
*/

/*******************************
1.General
2.Helper
3.Navbar
4.Hero section
5.Testimonial
6.Pricing
7.Subscribe
8.Footer
9.Responsive
********************************/

@import "variables";

/*******************
    1.General
*******************/

body {
  font-size: $font-size-base;
  color: $body-color;
  font-family: $font-family-primary;
  background-color: #010b13;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark;
  font-family: $font-family-secondary;
  font-weight: $font-weight-medium;
}

a {
  text-decoration: none !important;
  outline: none;
}

p {
  line-height: 1.8;
  font-size: 15px;
}

/*******************
    2.Helper
*******************/

.card {
  border: none;
  box-shadow: $box-shadow;
  border-radius: 7px;
  margin-bottom: 30px;
}

.section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}

.vertical-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.bg-overlay {
  background-color: rgba(35, 37, 47, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.small-title {
  letter-spacing: 1px;
  font-size: 14px;
}

.btn {
  font-size: 0.9rem;
  padding: 0.7rem 1.4rem;
  font-weight: $font-weight-medium;
  transition: all 0.4s;
  border-radius: 7px;
  &:hover {
    transform: translateY(-4px);
    outline: none;
    text-decoration: none;
  }
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-rounded {
  border-radius: 30px;
}

@each $name, $value in $colors {
  .bg-#{$name} {
    background-color: #{$value} !important;
  }

  .bg-soft-#{$name} {
    background-color: rgba(($value), 0.1) !important;
  }
  .text-#{$name} {
    color: #{$value} !important;
  }

  // Icons
  .icon-dual-#{$name} {
    color: $value;
    fill: rgba($value, 0.2);
  }

  .btn-#{$name} {
    color: $black;
    background: #{$value};
    border-color: #{$value};
    box-shadow: 0 8px 14px rgba($value, 0.2);

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      box-shadow: 0px 16px 31px -16px #{$value};

      background: darken($value, 4%);
      border-color: darken($value, 4%);
    }
  }

  .btn-outline-#{$name} {
    color: #{$value};
    border-color: #{$value};
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-width: 2px;
    border-style: solid;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      box-shadow: 0px 16px 31px -16px #{$value};
      background: darken($value, 4%);
      border-color: darken($value, 4%);
    }
  }
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.icons-sm {
  height: 16px;
  width: 16px;
}

.icons-md {
  height: 32px;
  width: 32px;
}

.icons-lg {
  height: 40px;
  width: 40px;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.avatar-md {
  height: 3.5rem;
  width: 3.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/******************
    3.Navbar
*******************/

.navbar-custom {
  padding: 20px 0px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;

  .navbar-nav {
    .nav-item {
      .nav-link {
        line-height: 26px;
        color: rgba($dark, 0.8);
        color: #b5b5b5cc;
        transition: all 0.4s;
        background-color: transparent !important;
        padding: 6px 10px;
        margin: 0 7px;
        &:hover,
        &:active {
          color: $primary;
        }
      }
      &.active {
        .nav-link {
          color: $primary;
        }
      }
    }
  }

  .navbar-btn {
    margin-left: 10px;
    // color: #000000;
    // background: rgba($primary, 1);
    // border-color: transparent;
    // box-shadow: none;

    &:hover {
      // color: #000000;
      // box-shadow: 0px 16px 31px -16px $primary;
      // background: darken($primary, 4%);
      // border-color: darken($primary, 4%);
    }
  }
}

.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: $white;
}

/*--- navbar Light ---*/

.navbar-light {
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: #b5b5b5cc;
        &:hover,
        &:active {
          color: $white;
        }
      }
      &.active {
        .nav-link {
          color: $white;
        }
      }
    }
  }
  .logo {
    .logo-light {
      display: inline-block;
    }
    .logo-dark {
      display: none;
    }
  }

  .nav-sticky {
    .logo {
      .logo-dark {
        display: inline-block;
      }
      .logo-light {
        display: none;
      }
    }
  }
}

/*--- navbar sticky ---*/

.logo-dark {
  &:hover {
    filter: brightness(0.76);
  }
}

.nav-sticky {
  &.navbar-custom {
    margin-top: 0px;
    padding: 10px 0px;
    background-color: $white;
    box-shadow: 0 10px 33px -14px rgba(0, 0, 0, 0.1);
    .logo {
      .logo-light {
        display: none;
      }
      .logo-dark {
        display: inline-block;
      }
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: #b5b5b5cc;
          &:hover,
          &:active {
            color: $primary;
          }
        }
        &.active {
          .nav-link {
            color: $primary;
          }
        }
      }
    }
  }
}

/******************
    4.Hero section
*******************/

.hero-section {
  padding-top: 250px;
  // overflow: hidden;

  &.index {
    padding-top: 215px;
  }
}

.home-img {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 690px;
    height: 473px;
    background-color: rgb(52 52 52 / 16%);
    border-radius: 30% 70% 59% 41%/30% 38% 62% 70%;
    z-index: -1;
    top: 90px;
    right: 15px;
  }

  &.flexbot::before {
    top: 185px;
    right: -277px;
  }
}

/*--- Hero 2 ---*/

.hero-section-2 {
  padding: 230px 0px 170px 0px;
}

.registration-form {
  .form-control {
    box-shadow: none;
    &:focus {
      border-color: $primary;
    }
  }
}

/*--- Hero 5 ---*/

.hero-section-5 {
  padding: 300px 0px 220px 0px;
}

.play-icon-circle {
  height: 50px;
  width: 50px;
  background-color: $success;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.play-iconbar {
  color: $white;
  font-size: 17px;
}

/******************
    5.Testimonial
*******************/

.testi-carousel {
  .owl-nav {
    position: absolute;
    top: -67px;
    right: 0;
    margin-top: 0px;

    button {
      outline: 0;
      border-radius: 50% !important;
      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        background: rgba($primary, 0.2);
        color: $primary;
        border-radius: 50%;
        font-size: 20px;
      }

      &:hover {
        span {
          background: $primary;
          color: $white;
        }
      }
    }
  }

  .owl-item {
    padding: 0px 10px;
  }

  .owl-dots {
    .owl-dot {
      &:focus {
        outline: 0;
      }
      span {
        background: rgba($primary, 0.2);
        border: 2px solid transparent;
      }

      &.active,
      &:hover {
        span {
          background: $white;
          border: 2px solid $primary;
        }
      }
    }
  }
}

/********** Clients ***********/

.client-images {
  img {
    max-height: 28px;
    width: auto !important;
    margin: 10px auto;
    margin-top: 16px;
    margin-bottom: 16px;
    opacity: 0.82;
    transition: all 0.5s;
    &:hover {
      opacity: 1;
    }
  }
}

/******************
    6.Pricing
*******************/

.pricing-nav-tabs {
  display: inline-block;
  background-color: $white;
  box-shadow: $box-shadow;
  padding: 5px;
  border-radius: 7px;
  li {
    display: inline-block;
    .nav-link {
      border-radius: 7px;
      color: $body-color;
      padding: 0.6rem 1.4rem;
      &.active {
        background-color: $primary;
      }
    }
  }
}

.pricing-plan {
  max-width: 415px;
  margin: 0px auto;
  margin-top: 30px;
}

/******************
    7.Subscribe
*******************/

.subscribe {
  .form-control {
    height: 46px;
    border-radius: 7px;
    font-size: 15px;
    box-shadow: none;
    &:focus {
      border-color: $primary;
    }
  }
}

/******************
    8.Footer
*******************/

.footer {
  background-color: $footer-bg;
  background-color: #08102a;
  color: rgba($white, 0.5);
  padding-top: 80px;
  // padding-bottom: 40px;
}

.footer-list-title {
  font-size: 16px;
  color: $white;
}

.footer-list-menu {
  li {
    a {
      display: inline-block;
      font-size: 15px;
      color: rgba($white, 0.5);
      margin-bottom: 14px;
      transition: all 0.4s;
      &:hover {
        color: rgba($white, 0.9);
      }
    }
  }
}

/******************
    9.Responsive
*******************/

@media (max-width: 991px) {
  .navbar-collapse {
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
  }
  .navbar-custom {
    margin-top: 0px;
    padding: 16px 0px !important;
    // background-color: $light !important;
    box-shadow: 0 10px 33px -14px rgba(0, 0, 0, 0.1);

    &.navbar-expand-lg > .container {
      width: 90%;
    }

    .logo {
      img {
        height: 32px;
      }
    }
    .navbar-nav {
      flex-direction: row;
      .nav-item {
        .nav-link {
          transition: all 0.4s;
          margin: 0px;
          margin-left: 12px;
          padding: 6px 0px;
          color: #b1b1b1e6;
        }
        &.active {
          .nav-link {
            color: $primary;
          }
        }
      }
    }

    .navbar-btn {
      margin-left: 0px;
    }
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgba($dark, 0.9);
  }

  .hero-section {
    padding-top: 130px;
  }
}

.hero-title {
  font-size: 51px;
  font-weight: 700;
  color: #fff;
}

.h-100 {
  height: 100% !important;
}

@media (max-width: 767.98px) {
  .hero-title {
    font-size: 32px;
  }

  .email-submit {
    margin: auto;
  }
}

@media (max-width: 575.98px) {
  .testi-carousel {
    .owl-nav {
      display: none;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bb-logo-nav {
  max-width: 200px;
}

.header-navigation {
  padding: 2.5rem;
  padding-top: 3rem;
}

.soc-logo-nav {
  width: 35px;
  margin-right: 12px;
}

.nav-right-container {
  display: flex;
}

.btn-lg {
  padding: 1.1rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.4;
  border-radius: 12px;
}

.color-blk {
  color: #000 !important;
}

.mt-10 {
  margin-top: 10rem;
}

.email-input {
  //   width: fit-content;
}

.email-submit {
  width: fit-content;
}

.mw-800 {
  max-width: 800px;
}

.home-gradient-one {
  box-sizing: border-box;
  right: 0;
  left: auto;
  bottom: auto;
  top: 0;
  position: absolute;
  background-image: radial-gradient(
    circle farthest-side at 100% 10%,
    rgba(111, 117, 255, 0.2),
    transparent 88%
  );
  z-index: 0;
  height: 64rem;
  width: 64rem;

  @media screen and (max-width: 767px) {
    & {
      height: 30rem;
      width: 30rem;
    }
  }

  @media screen and (max-width: 991px) {
    & {
      height: 48rem;
      width: 48rem;
    }
  }

  @media screen and (max-width: 479px) {
    & {
      height: 22rem;
      width: 22rem;
    }
  }
}

.home-gradient-two {
  box-sizing: border-box;
  width: 48rem;
  height: 48rem;
  top: 48rem;
  z-index: 0;
  background-image: radial-gradient(
    circle closest-corner at 0 center,
    rgba(111, 117, 255, 0.2) 14%,
    transparent
  );
  position: absolute;
  bottom: auto;
  left: 0;
  right: auto;

  @media screen and (max-width: 767px) {
    & {
      width: 30rem;
      height: 30rem;
      top: 54rem;
    }
  }

  @media screen and (max-width: 991px) {
    & {
      width: 48rem;
      height: 48rem;
    }
  }

  @media screen and (max-width: 479px) {
    & {
      width: 22rem;
      height: 22rem;
      max-height: 100vw;
      max-width: 100vw;
    }
  }
}

.home-gradient-two-b {
  box-sizing: border-box;
  right: auto;
  left: 0;
  bottom: auto;
  top: 68rem;
  position: absolute;
  background-image: radial-gradient(
    circle closest-corner at 0 center,
    rgba(111, 117, 255, 0.2) 8%,
    transparent
  );
  z-index: 0;
  height: 48rem;
  width: 48rem;

  @media screen and (max-width: 767px) {
    & {
      height: 30rem;
      width: 30rem;
    }
  }

  @media screen and (max-width: 991px) {
    & {
      height: 48rem;
      width: 48rem;
    }
  }

  @media screen and (max-width: 479px) {
    & {
      max-width: 100vw;
      max-height: 100vw;
      height: 22rem;
      width: 22rem;
    }
  }
}

.home-gradient-three-b {
  box-sizing: border-box;
  width: 70rem;
  height: 70rem;
  z-index: 0;
  background-image: radial-gradient(
    circle closest-corner at 100% center,
    rgba(0, 227, 180, 0.06) 14%,
    transparent
  );
  position: absolute;
  top: 118rem;
  bottom: auto;
  left: auto;
  right: 0;

  @media screen and (max-width: 767px) {
    & {
      width: 30rem;
      height: 30rem;
    }
  }

  @media screen and (max-width: 991px) {
    & {
      width: 48rem;
      height: 48rem;
    }
  }

  @media screen and (max-width: 479px) {
    & {
      width: 22rem;
      height: 22rem;
    }
  }
}

.home-gradient-four {
  box-sizing: border-box;
  right: auto;
  left: 0;
  bottom: auto;
  top: 180rem;
  position: absolute;
  background-image: radial-gradient(
    circle closest-corner at 16% center,
    rgba(111, 117, 255, 0.2) 11%,
    transparent 92%
  );
  z-index: 0;
  height: 64rem;
  width: 64rem;

  @media screen and (max-width: 767px) {
    & {
      height: 30rem;
      width: 30rem;
    }
  }

  @media screen and (max-width: 991px) {
    & {
      height: 48rem;
      width: 48rem;
    }
  }

  @media screen and (max-width: 479px) {
    & {
      max-width: 100vw;
      max-height: 100vw;
      height: 22rem;
      width: 22rem;
    }
  }
}

.home-gradient-four-b {
  box-sizing: border-box;
  right: auto;
  left: 0;
  bottom: auto;
  top: 210rem;
  position: absolute;
  background-image: radial-gradient(
    circle closest-corner at 4% center,
    rgba(111, 117, 255, 0.16) 11%,
    transparent 92%
  );
  z-index: 0;
  height: 64rem;
  width: 64rem;

  @media screen and (max-width: 767px) {
    & {
      height: 30rem;
      width: 30rem;
    }
  }

  @media screen and (max-width: 991px) {
    & {
      height: 48rem;
      width: 48rem;
    }
  }

  @media screen and (max-width: 479px) {
    & {
      max-width: 100vw;
      max-height: 100vw;
      height: 22rem;
      width: 22rem;
    }
  }
}

.home-gradient-five {
  box-sizing: border-box;
  right: 0;
  left: auto;
  bottom: auto;
  top: 310rem;
  position: absolute;
  background-image: radial-gradient(
    circle closest-corner at 88% center,
    rgba(0, 227, 180, 0.12) 14%,
    transparent
  );
  z-index: 0;
  height: 70rem;
  width: 70rem;

  @media screen and (max-width: 767px) {
    & {
      height: 30rem;
      width: 30rem;
    }
  }

  @media screen and (max-width: 991px) {
    & {
      height: 48rem;
      width: 48rem;
    }
  }

  @media screen and (max-width: 479px) {
    & {
      height: 22rem;
      width: 22rem;
    }
  }
}

.homepage {
  overflow: hidden;
  position: relative;
}

.col-grey {
  color: $body-color !important;
}

.hero-bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: -2;
}

.hero-bg-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: -1;
}

.color-w {
  color: #fff !important;
}

.col-footer {
  color: rgba(255, 255, 255, 0.5) !important;
}

.footer a {
  display: inline-block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.4s;

  p {
    margin-bottom: 7px;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.9);
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #737679;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid #00e3b4;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #d3d3d3;
    background-color: #000;
    border-color: #00e3b4;
    outline: 0;
    box-shadow: none;
  }
}

.b-tools-btn {
  color: #00e3b4;
  border-color: #00e3b4;
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-width: 2px;
  border-style: solid;
  // margin-right: 10px;
  border-radius: 30px;
  cursor: pointer;
  width: fit-content;
  min-width: 113px;
}

.b-tools-btn.selected {
  box-shadow: 0px 16px 31px -16px #00e3b4;
  background: #00cfa4;
  border-color: #00cfa4;
  color: #000;
}

.justify-space-between {
  justify-content: space-between;
}

.b-blocks-display {
  margin-top: 25px;
}

.flexbot-video {
  position: relative;
  z-index: 1;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  width: 90%;
  border: 1px solid #c5c5c51a;
  border: 1px solid #c5c5c540;
  border-radius: 8px;
}

.block-container {
  margin-top: 40px;
  border-radius: 12px;
  border: 2px solid #00cfa4;

  &.released {
    box-shadow: 0px 16px 16px -20px #00e3b4;
    cursor: pointer;
    transform: translateY(0);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-8px);
    }
  }
  &.hot {
    border-color: #e1b530;
  }
  &.med {
    border-color: #e38902;
  }
  &.low {
    border-color: #c43333;
  }

  background-color: #010b13;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  text-align: left;

  .block-desc {
    font-size: 0.8rem;
    color: #c5c5c5;
  }

  .block-top-container {
    position: relative;
    padding-right: 32%;
    width: 100%;

    .block-title {
      font-size: 1rem;
      color: #e6e6e6;
      margin-bottom: 5px;
    }

    .block-expected {
      position: absolute;
      top: 0;
      right: 0;
      padding: 2px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 0.6rem;
      border-radius: 8px;
      font-weight: 700;
      white-space: nowrap;
      color: #e6e6e6;

      &.released {
        background-color: #00cfa4;
        color: #000;
      }
      &.hot {
        border: 1px solid #e1b530;
      }
      &.med {
        border: 1px solid #e38902;
      }
      &.low {
        border: 1px solid #c43333;
      }
    }
  }
}
.navbar-brand {
  padding-left: 0.5rem;
}

.navbar-abs-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.landing-title {
  font-size: 2.85rem;
}

.landing-subtext {
  font-size: 1.2rem;

  a {
    color: #00cfa4;

    &:hover {
      color: #00e3b4;
    }
  }
}

.footer-newsletter-title {
  font-size: 1.55rem;
  font-weight: 700;
  background: -webkit-linear-gradient(#00cfa4, #00e3b4);
  background: -webkit-linear-gradient(#00e3b4, #00e3b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3;
}

.footer-newsletter-subtext {
  font-size: 0.88rem;
  color: #c5c5c5;
  margin-bottom: 10px;
}

.form-control.footer-subscribe {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000000;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}
.footer_form {
  max-width: 215px;
  height: 100%;
  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;
}
.footer_form-submit {
  background-color: transparent;
  background-image: url(https://assets-global.website-files.com/5f973c970bea5548ad4287ef/62c700d4e10c1f03508b1c19_button-arrow.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  padding-right: 2rem;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
  width: 20px;
}
.footer_form-submit:focus {
  color: #707070;
  background-color: #ffffff00;
  border: none;
  outline: 0;
  box-shadow: none;
}
.footer-form_text-field {
  height: 100%;
  margin-bottom: 0;
  padding-left: 1rem;
}
.w-input {
  width: 100%;
  height: 38px;
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.about-us-section {
  padding-top: 135px;
  .team-container {
    margin-top: 30px;
    .team-member {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      line-height: 1;
      color: #00e3b4;
      text-align: center;
      margin-bottom: 20px;

      .pic {
        border-radius: 8px;
        border: 1px solid #00e3b4;
        // padding: 14px;
        // background-image: url(/assets/images/speedline-anim-thicker.svg);
        // background-color: transparent;
        // background-position: 0%;
        // background-repeat: no-repeat;
        // background-size: cover;
        position: relative;

        .bg {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          z-index: 1;
          background-image: url(/assets/images/speedline-anim-thicker.svg);
          background-image: url(/assets/images/grad-bg2.svg);
          background-color: transparent;
          background-position: 0%;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .face-pic {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 98%;
          height: 98%;
          border-radius: 8px;
          z-index: 2;
        }
      }

      .name {
        margin-top: 10px;
        font-size: 1.6rem;
        font-weight: 700;
      }

      .role {
        font-size: 1rem;
        margin-top: 10px;
        color: #c5c5c5;
      }
    }
  }
}
